import React from "react"
import PropTypes from "prop-types"

const HeroText = ({ title, subtitle }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 left">
          <h2 className="bold uppercase">{title}</h2>
          {subtitle && <h4 className="uppercase">{subtitle}</h4>}
        </div>
      </div>
    </div>
  )
}

HeroText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default HeroText
