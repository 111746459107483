import React from "react"

const LinkList = () => {
  return (
    <div className="align-bottom text-center">
      <ul className="list-inline social-list mb24">
        <li>
          <a href="mailto:tayoo3@vt.edu">
            <i className="ti-email"></i>
          </a>
        </li>
        <li>
          <a href="https://facebook.com/tayooladelet">
            <i className="ti-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/heartvslogic/">
            <i className="ti-instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default LinkList
