import React from "react"
import PropTypes from "prop-types"

const HeroImage = ({ bannerImage }) => {
  const defaultImage = {
    src: "/img/banner.jpg",
    alt: "background placeholder",
  }

  return (
    <div
      className="background-image-holder fadeIn"
      style={{
        transform: "translate3d(0px, 0px, 0px)",
        background: bannerImage ? bannerImage.file.url : defaultImage.src,
      }}
    >
      <img
        alt={bannerImage ? bannerImage?.title : defaultImage.alt}
        className="background-image"
        src={bannerImage ? bannerImage?.file?.url : defaultImage.src}
        style={{ display: "none" }}
      />
    </div>
  )
}

HeroImage.propTypes = {
  bannerImage: PropTypes.object,
}

export default HeroImage
