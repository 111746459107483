import React from "react"
import PropTypes from "prop-types"

const defaultTitle = "Share !!!"
const defaultSubtitle = "Please tell your friends about me. Don't be selfish 😂"

export default function Share({
  backgroundImage,
  title = defaultTitle,
  subtitle = defaultSubtitle,
}) {
  const defaultImage = {
    src: "/img/collage1.jpg",
    alt: "background placeholder",
  }

  return (
    <section className="image-bg overlay">
      <div className="background-image-holder">
        <img
          alt={backgroundImage ? backgroundImage.title : defaultImage.alt}
          className="background-image"
          src={backgroundImage ? backgroundImage.file.url : defaultImage.src}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h4 className="uppercase mb16">{title}</h4>
            <p className="lead mb64">{subtitle}</p>
          </div>
        </div>
        {/* <!--end of row--> */}
        <div className="row">
          <div className="col-sm-12 text-center">
            <a
              className="btn btn-lg"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fheartvslogic.com&quote=Heart%20vs%20Logic"
              title="Share on Facebook"
              target="_blank"
              rel="noreferrer"
            >
              Share on Facebook <i className="ti-facebook"></i>
            </a>
            <a
              className="btn "
              href="https://twitter.com/intent/tweet?source=https%3A%2F%2Fheartvslogic.com&text=Heart%20vs%20Logic:%20https%3A%2F%2Fheartvslogic.com&via=Tayo_Oladele"
              target="_blank"
              title="Tweet"
              rel="noreferrer"
            >
              Tweet <i className="ti-twitter"></i>
            </a>
            <a
              className="btn btn-lg"
              href="http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fheartvslogic.com&media=https://www.heartvslogic.com/img/collage1.jpg&description=I%20am%20a%20Portrait%20and%20Events%20Photographer%20in%20Blacksburg%2C%20Virginia.%20I%20am%20currently%20a%20senior%20in%20Virginia%20Tech."
              target="_blank"
              title="Pin it"
              rel="noreferrer"
            >
              Pin it! <i className="ti-pintrest"></i>
            </a>
            <a
              className="btn btn-lg"
              href="http://www.reddit.com/submit?url=https%3A%2F%2Fheartvslogic.com&title=Heart%20vs%20Logic"
              target="_blank"
              title="Submit to Reddit"
              rel="noreferrer"
            >
              Reddit <i className="ti-reddit"></i>
            </a>
            <a
              className="btn btn-lg"
              href="mailto:?subject=Heart%20vs%20Logic&body=I%20am%20a%20Portrait%20and%20Events%20Photographer%20in%20Blacksburg%2C%20Virginia.%20I%20am%20currently%20a%20senior%20in%20Virginia%20Tech.:%20https%3A%2F%2Fheartvslogic.com"
              target="_blank"
              title="Send email"
              rel="noreferrer"
            >
              Mail It <i className="ti-email"></i>
            </a>
          </div>
        </div>
        {/* <!--end of row--> */}
      </div>
      {/* <!--end of container--> */}
    </section>
  )
}

Share.propTypes = {
  backgroundImage: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
