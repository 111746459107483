import React from "react"
import LinkList from "../LinkList"
import HeroImage from "./components/HeroImage"
import HeroText from "./components/HeroText"

const Index = ({ heroImage: bannerImage, title, subtitle }) => {
  return (
    <section className="pt240 pb240 image-bg">
      <HeroImage bannerImage={bannerImage} />
      <HeroText title={title} subtitle={subtitle} />
      <LinkList />
    </section>
  )
}

export default Index
