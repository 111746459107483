import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Share from "../../components/Share"
import Projects from "./components/projects"

const Featured = props => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulGallery(filter: { featured: { eq: true } }) {
        nodes {
          id
          title
          subtitle
          category
          slug
          thumbnail {
            id
            title
            fluid(
              maxWidth: 2304
              maxHeight: 1536
              quality: 100
              toFormat: AUTO
              resizingBehavior: THUMB
            ) {
              src
            }
          }
        }
      }
    }
  `)
  const {
    allContentfulGallery: { nodes },
  } = query

  return (
    <Layout>
      <Hero title="FEATURED WORK" subtitle="Curated Collections" />
      <Projects features={nodes} />
      <Share />
    </Layout>
  )
}

export default Featured
